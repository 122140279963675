import Header from "../Header";
import "./index.css";
import AboutUs from "../aboutus";
import Chooseus from "../chooseus";
import Reach from "../reach";
import Services from "../service";
import Trading from "../trading";
import COC from "../coc";
import Management from "../management";
import ContactUs from "../contactus";
export default function home() {
  return (
    <div className="page">
      <section>
        <Header />
      </section>

      <section>
        <AboutUs />
      </section>
      <section>
        <Chooseus />
      </section>
       <section>
        {" "}
        <COC />
      </section>
      <section>
        {" "}
        <Management />
      </section>
      <section>
        {" "}
        <ContactUs />
      </section>

      {/* <section>
        {" "}
        <ThankYou />
      </section> */}
    </div>
  );
}
