import pump1 from "../../assets/pump1.png";
import pump2 from "../../assets/pump2.png";
import pump3 from "../../assets/pump3.png";
import pump4 from "../../assets/pump4.png";
import pump5 from "../../assets/pump5.png";
import pump6 from "../../assets/pump6.png";
import "./index.css";
export default function Pumps() {
  return (
    <div className="pumps">
      <h1 className="orange">
        <label className="black">Pumps at it's Best </label> &nbsp;By ABIZZ
      </h1>
      <span>(MAKE: KIRLOSKER, REXROTH, CROMPTON, KSB &amp; More.)</span>
      <div className="row">
        <div className="col-sm-4 col-xs-12">
          <img src={pump1} alt="centrifugal pump" />
          centrifugal pump
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={pump2} alt="sumersible pump" />
          sumersible pump
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={pump3} alt="rotatory pump" />
          rotatory pump
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={pump4} alt="gear pump" />
          gear pump
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={pump5} alt="axial piston pump" />
          axial piston pump
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={pump6} alt="reciprocating pump" />
          reciprocating pump
        </div>
      </div>{" "}
    </div>
  );
}
