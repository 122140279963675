import home from "../home";
import Products from "../Products";
import Services from "../service";
import ContactUs from "../contactus";
export const routes = [
  { name: "Home", route: "", component: home },
  {
    name: "Services",
    route: "services",
    component: Services,
  },
  {
    name: "Products",
    route: "products",
    component: Products,
  },
  {
    name: "Contact Us",
    route: "contact",
    component: ContactUs,
  },
  // {
  //   name: "Enquiry",
  //   route: "enquiry",
  //   component: ComingSoon,
  // },
];
