import mech1 from "../../assets/mach1.png";
import mech2 from "../../assets/mach2.png";
import mech3 from "../../assets/mach3.png";
import "./index.css";
export default function Machines() {
  return (
    <div className="mach">
      <h1>
        {" "}
        <label className="black">Machineries </label>
        <label className="orange"> &nbsp; &amp; Equipment's</label>
      </h1>
      <div className="row">
        <div className="col-xs-12 col-md-4">
          <img src={mech1} alt="machine" />
          Air compressor and Transformer
        </div>

        <div className="col-xs-12 col-md-4">
          <img src={mech2} alt="motor plant" />
          Cooling tower
        </div>
        <div className="col-xs-12 col-md-4">
          <img src={mech3} alt="machine 2" />
          RO Plant and Lab Equipment's
        </div>
        <span> *(given list is indicative and not exhaustive)</span>
      </div>
    </div>
  );
}
