import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar";
import { routes } from "./components/navbar/routes";
import Comingsoon from "./components/comingsoon";

function App() {
  const [comingsoon] = useState(false);
  
  if (comingsoon) {
    return (
      <>
        <Comingsoon />
      </>
    );
  }
  return (
    <>
      <Navbar />
      <Routes>
        {routes.map((i) => (
          <Route path={i.route} element={<i.component />}></Route>
        ))}
      </Routes>
    </>
  );
}

export default App;
