import coc from "../../assets/cocwithbg.png";
import "./index.css";
export default function COC() {
  return (
    <div className="coc">
      <h1 className="heading">
        <label className="black">Code of </label>{" "}
        <label className="orange">Bussiness Conduct</label>
      </h1>
      <img src={coc} alt="code of conduct" />
    </div>
  );
}
