import "./index.css";
import reach from "../../assets/reachwithbg.png";

export default function Reach() {
  return (
    <div className="reach">
      {" "}
      <h1 className="orange">
        <label className="black">Our </label> &nbsp;Reach
      </h1>
      <img src={reach} alt={"our reach"} />
    </div>
  );
}
