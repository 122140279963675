import "./index.css";
import logo from "../../assets/logo-white.png";
export default function ComingSoon() {
  return (
    <div className="coming">
      <h1>We are coming Soon!</h1>
      <img src={logo} alt="abizz" />
      <h2>Abizz Engineering Pvt Ltd</h2>
    </div>
  );
}
