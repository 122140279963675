import ge1 from "../../assets/ge1.png";
import ge2 from "../../assets/ge2.png";
import ge4 from "../../assets/ge4.png";
import ge5 from "../../assets/ge5.png";
import ge6 from "../../assets/ge6.png";
import hand from "../../assets/handtools.jpg";
import ch from "../../assets/ch.jpg";
import tc from "../../assets/tc.jpg";
import lube from "../../assets/lube.jpg";
import clm from "../../assets/clm.jpg";
import rod from "../../assets/rod.jpeg";
import "./index.css";
export default function GeneralConsumable() {
  return (
    <div className="general">
      <h1>
        <label className="black">General </label>
        <label className="white">&nbsp;Consumables</label>
      </h1>
      <div className="row">
        <div className="col-sm-4 col-xs-12">
          <img src={ge2} alt="wires" />
          Wire ropes
        </div>

        <div className="col-sm-4 col-xs-12">
          {" "}
          <img src={ge1} alt="tools" />
          Gasket
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={ch} alt="chalk" />
          Hot chalk
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={ge5} alt="tools2" />
          Fittings{" "}
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={tc} alt="welding torch" />
          Welding torch
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={hand} alt="rollers" />
          Hand tools
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={ge4} alt="rollers" />
          Brushes, rollers
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={clm} alt="clamp" />
          Clamps
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={lube} alt="lubricants" />
          Lubricants
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={rod} alt="welding rod" />
          Welding rod
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={ge6} alt="equipment" />
          Lab apparatus
        </div>
      </div>{" "}
    </div>
  );
}
