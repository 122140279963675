import offer1 from "../../assets/offer1.png";
import offer2 from "../../assets/offer2.png";
import offer3 from "../../assets/offer3.png";
import offer4 from "../../assets/offer4.png";
import offer5 from "../../assets/offer5.png";
import offer6 from "../../assets/offer6.png";
import "./index.css";
export default function Offers() {
  return (
    <div className="bears">
      <h1 className="white">
        <label className="black">ABIZZ offers variety &nbsp; </label>of bearings{" "}
      </h1>
      <span>(MAKE: SKF,FAG,INA,KOYO,URB,NACHI &amp; Many More.)</span>
      <div className="row">
        <div className="col-sm-4 col-xs-12">
          <img src={offer1} alt="taper roller bearing" />
          taper roller bearing
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={offer2} alt="neddle roller bearing" />
          neddle roller bearing
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={offer3} alt="self aligning ball bearing" />
          self aligning ball bearing
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={offer4} alt="spherical roller bearing" />
          spherical roller bearing
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={offer5} alt="cylindrical roller bearing" />
          cylindrical roller bearing
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={offer6} alt="deep grove ball bearing" />
          deep grove ball bearing
        </div>
      </div>{" "}
    </div>
  );
}
