import "./index.css";
import logo from "../../assets/logo.png";
export default function Header() {
  return (
    <div className="center-flex">
      <img src={logo} id="logo-header" alt="abizz engineering" />
      <h1>Abizz Engineering</h1>

      <span>www.abizzgroup.com</span>
      <span>abizzgroup@gmail.com</span>
      <p>
        ABIZZ provides a diversified range of equipment and machineries. Our
        main goal is to provide our customers, cost effective &amp; high quality
        goods within given span of time under one roof.
      </p>
    </div>
  );
}
