import "./index.css";
import { routes } from "./routes";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import logo from "../../assets/banner-r.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
export default function Navbar() {
  const [toggle, setToggle] = useState(false);
  const [show, isShow] = useState(false);
  const intersectionCb = (enteries) => {
    if (enteries[0].isIntersecting && show !== false) {
      isShow(false);
    }
    if (!enteries[0].isIntersecting && show !== true) {
      isShow(true);
    }
  };
  useEffect(() => {
    const ob = new IntersectionObserver(intersectionCb);
    if (document.querySelector("#logo-header")) {
      ob.observe(document.querySelector("#logo-header") || null);
    } else {
      isShow(true);
    }
    return () => {
      if (document.querySelector("#logo-header")) {
        ob.unobserve(document.querySelector("#logo-header") || null);
        ob.disconnect();
      }
    };
  });
  return (
    <div>
      <div className="bar">
        {show ? <img src={logo} alt="abizz" /> : <div></div>}
        <button
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <FontAwesomeIcon icon={toggle ? faClose : faBars} size="lg" />
        </button>
      </div>
      {toggle && (
        <div className="nav">
          <div className="navigation">
            {routes.map((i) => (
              <Link onClick={() => setToggle(false)} to={i.route}>
                {i.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
