import mech1 from "../../assets/mech1.png";
import mech2 from "../../assets/mech2.png";
import mech3 from "../../assets/mech3.png";
import mech4 from "../../assets/mech4.png";
import mech5 from "../../assets/mech5.png";
import "./index.css";
export default function Mech() {
  return (
    <div className="mech">
      <h1>
        <label className="black">Speciality in </label>
        <label className="orange">
          &nbsp;Mechanical /Electrical Consumables &amp; Spares
        </label>
      </h1>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          <img src={mech1} alt="motors" />
          Pumps &amp; Motors
        </div>
        <div className="col-sm-6 col-xs-12">
          <img src={mech2} alt="valves and hoes" />
          Gears &amp; Bearings
        </div>
        <div className="col-sm-6 col-xs-12">
          <img src={mech3} alt="nuts and bearings" />
          Nozzle, Hoses &amp; Clamps
        </div>
        <div className="col-sm-6 col-xs-12">
          <img src={mech4} alt="cables" />
          Cables (HT &amp; LT)
        </div>
        <div className="col-sm-6 col-xs-12">
          <img src={mech5} alt="anchor bolts" />
          Fasteners &amp; Anchor Bolts
        </div>
        <div className="col-sm-12 col-xs-12 align-list">
          {"We also have specialities in "}
          <ul>
            <li> Mechanical Seal</li>
            <li> Rupture Disc</li>
            <li> Expansion Bellows</li>
            <li> Valves &amp; Instruments</li>

            <li> Induction Motor</li>
            <li> Power &amp; Hand Tools</li>
            <li>Pipe and Fittings</li>
            <li> Welding Equipment</li>
            <li>Slip on Caps</li>
          </ul>{" "}
        </div>
      </div>
    </div>
  );
}
