import ppe1 from "../../assets/ppe1.png";
import ppe2 from "../../assets/ppe2.png";
import ppe3 from "../../assets/ppe3.png";
import ppe5 from "../../assets/ppe5.png";
import ppe6 from "../../assets/ppe6.png";
import ppe7 from "../../assets/ppe7.png";
import ppe8 from "../../assets/ppe8.png";
import ppe9 from "../../assets/ppe9.png";
import ppe10 from "../../assets/ppe10.png";

import "./index.css";
export default function PersonalPe() {
  return (
    <div className="ppe">
      <h1 className="orange">
        <label className="black">Personal Protective &nbsp;</label> Equipment
      </h1>
      <div className="row">
        <div className="col-sm-4 col-xs-12">
          <img src={ppe9} alt="blue kit" />
          Uniforms
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={ppe1} alt="helmet" />
          Helmets{" "}
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={ppe6} alt="sheild" />
          Face shields &amp; Masks{" "}
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={ppe3} alt="sticky gloves" />
          Hand Gloves
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={ppe7} alt="shoes" />
          Shoes
        </div>
        <div className="col-sm-4 col-xs-12">
          {" "}
          <img src={ppe2} alt="orange jacket" />
          Jackets
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={ppe5} alt="googles" />
          Goggles
        </div>
        <div className="col-sm-4 col-xs-12">
          {" "}
          <img src={ppe8} alt="jacket" />
          Coverall
        </div>
        <div className="col-sm-4 col-xs-12">
          {" "}
          <img src={ppe10} alt="kit" />
          Protecive kits
        </div>
      </div>{" "}
    </div>
  );
}
