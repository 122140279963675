import React, { useState, useEffect } from "react";
import service from "../../assets/services.png";
import "./index.css";
import Reach from "../reach";
import Trading from "../trading";
export default function Services() {
  const [isImg, setImg] = useState(true);

  useEffect(() => {
    if (document.body.offsetWidth < 800) {
      setImg(false);
    } else {
      setImg(true);
    }
  }, [document.body.offsetWidth]);
  const clsName = isImg ? "col-sm-4 col-xs-12" : "col-sm-6 col-xs-12";
  return (
    <div className="services">
      <section>
        <h1>
          <label className="black">Our&nbsp;</label>
          <label className="white">Services</label>
        </h1>
        <span>
          ABIZZ provides you a complete range of project from{" "}
          <label className="black">
            “an idea to commissioning activities”&nbsp;
          </label>
          with highest quality and safety standards.
        </span>
        <div className="row">
          <div className={clsName}>
            <h2>Project Delivery</h2>
            <p>
              Our dedicated project engineers wlll control the whole process to
              finish the line in a cost- and timeefficient manner.
            </p>
            <h2>Installation and Erection</h2>
            <p>
              Our team take care of planning installations, transport logistics
              and construction site coordination, and lead turnkey construction
              projects.
            </p>
          </div>
          {isImg && (
            <div className={clsName}>
              <img src={service} alt={"our services"} id="services" />
            </div>
          )}
          <div className={clsName}>
            <h2>Procurement </h2>
            <p>
              Our Procurement Team will help you to find the best quality at
              lowest rates.
            </p>
            <h2> Commissioning </h2>
            <p>
              Our aim is to ensure and assure client assets are optimized as per
              their guidelines and needs.
            </p>
          </div>
        </div>
      </section>

      <section>
        {" "}
        <Reach />
      </section>
      <section>
        {" "}
        <Trading />
      </section>
    </div>
  );
}
