import "./index.css";
import fs from "../../assets/fs.png";
import hose from "../../assets/hoses.jpg";
import nozzle from "../../assets/nozzle.jpg";
import alarm from "../../assets/firealarm.jpg";
export default function FireSafety() {
  return (
    <div className="fs">
      <h1>
        <label className="black">Fire Safety </label>
        <label className="white">&nbsp;Equipment </label>
      </h1>
      <div className="row">
        <div className="col-sm-3 col-xs-12">
          <img src={alarm} alt="fire safety equipments" />
          Fire Alarm Systems
        </div>

        <div className="col-sm-3 col-xs-12">
          <img src={hose} alt="fire safety equipments" />
          Fire Hoses
        </div>

        <div className="col-sm-3 col-xs-12">
          <img src={nozzle} alt="fire safety equipments" />
          Nozzles
        </div>

        <div className="col-sm-3 col-xs-12">
          <img src={fs} alt="fire safety equipments" />
          Foam Equipments
        </div>
      </div>
    </div>
  );
}
