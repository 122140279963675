import "./index.css";
import trades from "../../assets/trades.png";
export default function Trading() {
  return (
    <div className="trading">
      <h1 className="white">
        <label className="black">Trading of&nbsp;</label>Equipments &amp;
        Machineries
      </h1>
      <div className="row">
        <div className="col-sm-4 col-xs-12">
          <p>
            We also offer trading solutions for instrumentation &amp; control
            items, supply of industrial items in the field of oil and gas,
            power, thermal, agriculture, e Vehicles and many more
          </p>
        </div>
        <div className="col-sm-4 col-xs-12">
          <img src={trades} alt="one stop for your needs" />
        </div>

        <div className="col-sm-4 col-xs-12">
          <div className="flex">
            <label className="ball ball-1"></label>
            <div>
              <h2> industrial equipments</h2>
              <span>
                Mechanical machineries, tools &amp; tackles, instruments, solar
                energy panels
              </span>
            </div>
          </div>
          <div className="flex">
            <label className="ball ball-2"></label>
            <h2> agriculture equipments</h2>
          </div>
          <div className="flex">
            <label className="ball ball-3"></label>
            <div>
              <h2>Fire &amp; safety equipments </h2>
              <span>
                Gas detection, fire fighting monitors &amp; hoses, personal
                protective equipments
              </span>{" "}
            </div>
          </div>
          <div className="flex">
            <div className="ball ball-4"></div>
            <h2>e-vehicles</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
