import "./index.css";
import choose from "../../assets/VRU_poster-removebg-preview.png";
export default function Chooseus() {
  return (
    <div className="section">
      <h1 className="chooseusheading">
        <span className="black">Why</span>
        <span className=""> Choose us</span>
      </h1>
      <div className="chooseus">
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <p>
              The prime aspiration is to support a pre-eminence in meeting the
              needs of the valuable customers in a cost effective &amp;
              professional execution to offer a strong backend support.
            </p>
            <p>
              We source our material from the world leaders in their respective
              fields to ensure customers get desired quality. All the sales
              activities in “Abizz” are backed by technical support &amp;
              assistance when required.
            </p>
            <p className="final">
              With over a years of experience in the Indian market, we are
              conglomerated with various manufacturing companies with a common
              mission to provide reliable and cost effective critical
              consumables, spare parts &amp; equipment for foundries, steel
              plants, Oil &amp; Gas &amp; other industries at most competitive
              prices
            </p>
          </div>
          <div className="col-sm-6 col-xs-12">
            <img src={choose} alt="why choose abizz" />
          </div>
          <div className="col-xs-12 "></div>
        </div>
      </div>
    </div>
  );
}
