import "./index.css";
import kp from "../../assets/kp.png";
import bs from "../../assets/bs.png";
import tl from "../../assets/tl.png";
export default function Management() {
  return (
    <div className="management">
      <h1 className="">Management</h1>
      <div className="row">
        <div className="col-sm-4 col-xs-12 paddo">
          <img src={kp} alt="kartik pandey" />
          <span>Kartik Pandey</span>
          <span>Founder and Director</span>
        </div>
        <div className="col-sm-4 col-xs-12 paddo">
          <img src={tl} alt="TARUN LOHUMI" />
          <span>Tarun Lohumi</span>
          <span>Co-Founder and Director</span>
        </div>
        <div className="col-sm-4 col-xs-12 paddo">
          <img src={bs} alt="Bhuwan Sharma" />
          <span>Bhuwan Sharma</span>
          <span>Co-Founder and Director</span>
        </div>
      </div>
    </div>
  );
}
