import "./index.css";
import visions from "../../assets/vision.png";
export default function AboutUs() {
  return (
    <div class="about">
      <h1>
        <span className="black">About </span>
        <span className="orange">us</span>
      </h1>
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          <p>
            ABIZZ Engineering Pvt. Ltd. is situated at Ghaziabad, India. ABIZZ
            is a service provider to the oil &amp; gas, power, medical, energy,
            agriculture sector.
          </p>
          <img src={visions} alt="company vision" />
        </div>
        <div className="col-sm-6 col-xs-12">
          <p>
            We are a team of experienced engineers who can provide solutions to
            your engineering, procurement, quality and fabrication related
            problems. We are specialised engineers for skid package projects
            which can handle any stage of project from enquiry stage engineering
            to detailed engineering, procurement, fabrication, instrumentation
            &amp; control, Factory acceptance test, Site erection, Installation
            and Commissioning till Site Acceptance Test.
          </p>
        </div>
        <div className="col-sm-12">
          <p>
            We understand that our clients simply want their projects delivered
            on time, on budget and to the desired quality.
          </p>
          <p>
            We manage the commissioning process to ensure that client
            expectations are met, risk mitigation is addressed, and a fully
            functioning and operational facility is handed over to the
            operations and facilities management teams.
          </p>
        </div>
      </div>
    </div>
  );
}
