import "./index.css";
import map from "../../assets/map.png";
export default function ContactUs() {
  return (
    <div className="contact">
      <h1>
        <span>Contact</span> <span className="white">Us</span>
      </h1>
      <br /> <br />
      <div className="row">
        <div className="col-sm-6 col-xs-12">
          <dl>
            {" "}
            <dt>
              <h1>Corporate Office</h1>
            </dt>
            <dd>
              <h1>
                {" "}
                FF-12, Mark Mall, Sec 4B, Vasundhara, Ghaziabad, U.P. 201012,
                India
              </h1>
            </dd>
            <dt>
              <h1> GST</h1>
            </dt>
            <dd>
              {" "}
              <h1> 09AAWCA1433A1ZV </h1>
            </dd>
            <dt>
              <h1>Email</h1>
            </dt>
            <dd>
              <h1>abizzgroup@gmail.com </h1>
            </dd>
          </dl>
        </div>
        <div className="col-sm-6 col-xs-12">
          <img src={map} alt="pin" />
        </div>
        <div className="col-xs-12">
          <dl>
            <dt>
              <h1>Mob </h1>
            </dt>
            <dd>
              <h1>+91-9910913517, +91-8920429402</h1>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
}
