import Mech from "../mech";
import Fire from "../firesafety";
import Personal from "../personalpe";
import General from "../generalconsume";
import Mach from "../machineries";
import Offers from "../offeringbears";
import Pumps from "../pumps";
export default function Products() {
  return (
    <>
      <section>
        <Mech />
      </section>
      <section>
        <Fire />
      </section>
      <section>
        <Personal />
      </section>
      <section>
        <General />
      </section>
      <section>
        <Mach />
      </section>
      <section>
        <Offers />
      </section>
      <section>
        <Pumps />
      </section>
    </>
  );
}
